import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createCampaign, getCampaign, getLookups, setCampaign } from "../../../core/actionCreators/adsenseActionCreator";
import SideNavbar from "../../components/Navbar/SideNavbar";
import Header from "../../components/Header/Header";
import CampaignHeader from "../../components/Campaign/CampaignHeader";
import { useSelector } from "react-redux";
import ViewBannerDimensionPopup from "../../components/Popup/ViewBannerDimensionPopup";
import CommonForm from "../../components/Campaign/CommonForm";
import BrandBasedCampaign from "../../components/Campaign/BrandBasedCampaign";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-hot-toast";
import { CAMPAIGN_SECTION_TYPES, CAMPAIGN_TYPES, CREATE_CAMPAIGN_FORM_ERRORS } from "../../../utils/constants";
import CategoryBasedCampaign from "../../components/Campaign/CategoryBasedCampaign";
import BrandCategoryBasedCampaign from "../../components/Campaign/BrandCategoryBasedCampaign";
import MSNBasedCampaign from "../../components/Campaign/MSNBasedCampaign";
import { removeDuplicatesFromArrayOfString } from "../../../utils/helper";
import PageBasedCampaign from "../../components/Campaign/PageBasedCampaign";
import ProductBasedCampaign from "../../components/Campaign/ProductBasedCampaign";
import { fetchProductsByCategoryAndBrand } from "../../../core/services/adsenseServices";


const Campaign = () => {
    const params = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const store: any = useSelector((s) => s)
    const { lookups, isLoading } = store.adsenseStore
    const { user } = store.authStore

    //Local States
    const [pageMode, setPageMode] = useState("CREATE")
    const [campaignId, setCampaignId] = useState("")
    const [viewBannerDimension, setViewBannerDimension] = useState(false)
    const [campaign, setcampaign] = useState({
        "campaignName": "",
        "campaignType": "",
        "startDate": "",
        "endDate": "",
        "status": "",
        "brand": {},
        "brandId": "",
        "brandName": "",
        "category": {},
        "categoryId": "",
        "categoryName": "",
        "platform": "PWA",
        "isMsnChecked": false,
        "isUniversal": false,
        "isApplicableForApp": false,
        "msns": [],
        "file": "",
        "_prevFile": "",
        "_multiCategorylastUpdatedAt": new Date,
        "bannerAdUnit": {
            "banners": []
        },
        "featuredProductUnit": {
            "msns": []
        },
        "promotedBrandUnit": {
            "banners": []
        },
        "videoUnit": {
            "banners": []
        },
        "createdBy": user.id || -1,
        "updatedBy": user.id || -1,
        "createdByEmail": user.email || "care@moglix.com",

        //For multi category, 
        "hasSiblings": false,
        "uniqueIdentifier": "",
        "categories": [],
        "segregatedCategories": {
            "l1Categories": [],
            "_l1Categories": [],
            "l2Categories": [],
            "_l2Categories": [],
            "l3Categories": [],
            "_l3Categories": []
        },
        "brands": [],
        "segregatedBrands": [],
        "relatedMsns": [],
        "bannerTypes": [],
        "inputType": ""
    } as any)
    const [sections, setSections] = useState([{
        "id": uuidv4(),
        "sectionType": ""
    }] as any)


    useEffect(() => {
        if (Object.keys(store.adsenseStore.campaign).length > 0) {
            const { campaign } = store.adsenseStore

            //handle page mode
            if (["DRAFT", "PAUSED"].includes(campaign.status)) {
                setPageMode("EDIT")
            }
            else {
                setPageMode("VIEW")
            }

            if (campaign.msns === null) {
                campaign.msns = []
            }
            if (campaign.file) {
                campaign._prevFile = campaign.file
            }

            if (campaign.segregatedCategories && Object.keys(campaign.segregatedCategories).length) {
                campaign.segregatedCategories._l1Categories = []
                campaign.segregatedCategories._l2Categories = []
                campaign.segregatedCategories._l3Categories = []
            }
            else {
                campaign.segregatedCategories = {
                    l1Categories: [],
                    _l1Categories: [],
                    l2Categories: [],
                    _l2Categories: [],
                    l3Categories: [],
                    _l3Categories: []
                }
            }
            setcampaign({ ...campaign })
            sections.pop()
            //handle section
            if (campaign.bannerAdUnit && campaign.bannerAdUnit.banners && campaign.bannerAdUnit.banners.length) {
                sections.push({
                    "id": uuidv4(),
                    "sectionType": CAMPAIGN_SECTION_TYPES.BANNER_AD_UNIT
                })
            }
            if (campaign.promotedBrandUnit && campaign.promotedBrandUnit.banners && campaign.promotedBrandUnit.banners.length) {
                sections.push({
                    "id": uuidv4(),
                    "sectionType": CAMPAIGN_SECTION_TYPES.PROMOTED_BRAND_UNIT
                })
            }
            if (campaign.videoUnit && campaign.videoUnit.banners && campaign.videoUnit.banners.length) {
                sections.push({
                    "id": uuidv4(),
                    "sectionType": CAMPAIGN_SECTION_TYPES.VIDEO_UNIT
                })
            }
            if (campaign.featuredProductUnit && campaign.featuredProductUnit.msns && campaign.featuredProductUnit.msns.length) {
                sections.push({
                    "id": uuidv4(),
                    "sectionType": CAMPAIGN_SECTION_TYPES.FEATURED_PRODUCT_UNIT
                })
            }
            setSections([...sections])
        }
    }, [store.adsenseStore.campaign])

    useEffect(() => {
        if (params.campaignId) {
            setCampaignId(params.campaignId)
            dispatch(getCampaign(params.campaignId, null))
        }
        return () => {
            setCampaignId("")
            dispatch(setCampaign({}))
        }
    }, [params]);

    useEffect(() => {
        dispatch(getLookups(`module=CAMPAIGN`, null))
    }, [])

    const validateCampaign = (campaign: any) => {
        if (!campaign.campaignName) {
            throw new Error(CREATE_CAMPAIGN_FORM_ERRORS.PLEASE_ADD_CAMPAIGN_NAME);
        }
        if (!campaign.startDate) {
            throw new Error(CREATE_CAMPAIGN_FORM_ERRORS.PLEASE_SELECT_START_DATE);
        }
        if (!campaign.endDate) {
            throw new Error(CREATE_CAMPAIGN_FORM_ERRORS.PLEASE_SELECT_END_DATE);
        }
        if (!campaign.platform) {
            throw new Error(CREATE_CAMPAIGN_FORM_ERRORS.PLEASE_SELECT_PLATFORM);
        }
        if (
            campaign.campaignType === CAMPAIGN_TYPES.PRODUCT_BASED_CAMPAIGN &&
            (!campaign.msns?.length || !campaign.bannerTypes?.length)
        ) {
            throw new Error('Invalid campaign');
        }
    };

    const validateCampaignType = (campaign: any) => {
        if (campaign.campaignType === "BRAND_BASED_CAMPAIGN" && !campaign.brandId && campaign.isUniversal == "false" && campaign.brands.length === 0) {
            throw new Error("Please select brand.");
        }
        if (campaign.campaignType === "CATEGORY_BASED_CAMPAIGN" && !campaign.categoryId && campaign.isUniversal == "false" && campaign.categories.length === 0) {
            throw new Error("Please select category.");
        }
        if (campaign.campaignType === "BRAND_CATEGORY_BASED_CAMPAIGN") {
            if (!campaign.categoryId && campaign.categories.length === 0) {
                throw new Error("Please select category.");
            }
            if (!campaign.brandId && campaign.brands.length === 0) {
                throw new Error("Please select brand.");
            }
        }
        if (campaign.campaignType === "MSNS_BASED_CAMPAIGN") {
            if (!campaign.file) {
                throw new Error("Oops, it seems like You forgot to upload the file.");
            }
            if (!campaign.file && campaign.msns.length) {
                throw new Error("Something went wrong while fetching msns from file.");
            }
        }
    };

    const handleFeaturedProductUnit = (featuredProductUnit: any) => {
        if (featuredProductUnit?.msns?.length === 0) {
            return null;
        }
        if (typeof featuredProductUnit?.msns === "string") {
            featuredProductUnit.msns = featuredProductUnit.msns.replace(/\s/g, "").split(",");
            featuredProductUnit.msns = removeDuplicatesFromArrayOfString(featuredProductUnit.msns);
            featuredProductUnit.msns = featuredProductUnit.msns.filter((str: any) => str !== "");
            if (featuredProductUnit.msns.length < 2) {
                throw new Error("Add at least 2 msn to proceed further.");
            }
            if (featuredProductUnit.msns.length > 20) {
                throw new Error("Only 20 msns are allowed to add.");
            }
        }
        return featuredProductUnit;
    };

    const handleBannerAdUnit = (bannerAdUnit: any) => {
        if (bannerAdUnit?.banners?.length) {
            bannerAdUnit.banners = bannerAdUnit.banners.filter((b: any) => {
                if (b && b.errors && Object.values(b.errors).filter((e) => e !== "").length) {
                    throw new Error(CREATE_CAMPAIGN_FORM_ERRORS.NOT_ABLE_TO_SAVE_CAMPAIGN_KINDLY_CHECK_FOR_ERRORS);
                }
                return b.file || b.pictureUrl || b.landingPageUrl;
            });
            if (!bannerAdUnit.banners.length) {
                return null;
            }
        } else {
            return null;
        }
        return bannerAdUnit;
    };

    const handlePromotedBrandUnit = (promotedBrandUnit: any) => {
        if (promotedBrandUnit?.banners?.length) {
            promotedBrandUnit.banners = promotedBrandUnit.banners.filter((b: any) => {
                if (b && b.errors && Object.values(b.errors).filter((e) => e !== "").length) {
                    throw new Error(CREATE_CAMPAIGN_FORM_ERRORS.NOT_ABLE_TO_SAVE_CAMPAIGN_KINDLY_CHECK_FOR_ERRORS);
                }
                return b.file || b.pictureUrl || b.categoryName || b.landingPageUrl;
            });
            if (!promotedBrandUnit.banners.length) {
                return null;
            }
        } else {
            return null;
        }
        return promotedBrandUnit;
    };

    const handleVideoUnit = (videoUnit: any) => {
        if (videoUnit?.banners?.length) {
            videoUnit.banners = videoUnit.banners.filter((b: any) => {
                if (b && b.errors && Object.values(b.errors).filter((e) => e !== "").length) {
                    throw new Error(CREATE_CAMPAIGN_FORM_ERRORS.NOT_ABLE_TO_SAVE_CAMPAIGN_KINDLY_CHECK_FOR_ERRORS);
                }
                return b.videoName || b.externalVideoLink;
            });
            if (!videoUnit.banners.length) {
                return null;
            }
        } else {
            return null;
        }
        return videoUnit;
    };

    const createCampaignsSequentially = (campaignsToCreate: any[], index: number = 0) => {
        if (index >= campaignsToCreate.length) {
            navigate("/campaign");
            return; // All campaigns have been created
        }

        const campaign = campaignsToCreate[index];
        dispatch(createCampaign(campaign, () => {
            createCampaignsSequentially(campaignsToCreate, index + 1);
        }));
    }

    const prepareCampaignPayload = async (campaign: any) => {
        let requestPayload: any = { ...campaign };

        requestPayload.featuredProductUnit = handleFeaturedProductUnit(requestPayload.featuredProductUnit);
        requestPayload.bannerAdUnit = handleBannerAdUnit(requestPayload.bannerAdUnit);
        requestPayload.promotedBrandUnit = handlePromotedBrandUnit(requestPayload.promotedBrandUnit);
        requestPayload.videoUnit = handleVideoUnit(requestPayload.videoUnit);

        requestPayload.isMsnChecked = requestPayload.isMsnChecked === "true";

        requestPayload.isUniversal = JSON.parse(requestPayload.isUniversal || 'false');
        requestPayload.isMsnChecked = JSON.parse(requestPayload.isMsnChecked || 'false');
        requestPayload.isApplicableForApp = JSON.parse(requestPayload.isApplicableForApp || 'false');

        const aggregatedCategories: any[] = [];

        if (requestPayload?.segregatedBrands) {
            requestPayload.brands = requestPayload.segregatedBrands.map((b: any) => b.brandId);
        }

        if (requestPayload?.segregatedCategories?._l1Categories && requestPayload.segregatedCategories._l1Categories.length) {
            requestPayload.segregatedCategories.l1Categories = requestPayload.segregatedCategories._l1Categories
        }

        if (requestPayload?.segregatedCategories?._l2Categories && requestPayload.segregatedCategories._l2Categories.length) {
            requestPayload.segregatedCategories.l2Categories = requestPayload.segregatedCategories._l2Categories
        }

        if (requestPayload?.segregatedCategories?._l3Categories && requestPayload.segregatedCategories._l3Categories.length) {
            requestPayload.segregatedCategories.l3Categories = requestPayload.segregatedCategories._l3Categories
        }

        if (requestPayload?.segregatedCategories) {
            aggregatedCategories.push(...requestPayload.segregatedCategories["l1Categories"].map((c: any) => c.categoryCode));
            aggregatedCategories.push(...requestPayload.segregatedCategories["l2Categories"].map((c: any) => c.categoryCode));
            aggregatedCategories.push(...requestPayload.segregatedCategories["l3Categories"].map((c: any) => c.categoryCode));
            requestPayload.categories = aggregatedCategories
        }

        //IF CAMPAIGN_TYPE ==== "PRODUCT_BASED_CAMPAIGN"
        if (campaign.campaignType === CAMPAIGN_TYPES.PRODUCT_BASED_CAMPAIGN && campaign.inputType === "CATEGORY_BRAND") {
            let relatedMsns: any = []
            if (campaign.segregatedCategories.l2Categories.length && campaign.segregatedBrands.length) {
                for (let category of campaign.segregatedCategories.l2Categories) {
                    for (let brand of campaign.segregatedBrands) {
                        const { data } = await fetchProductsByCategoryAndBrand(`inStock=true&categoryId=${category.categoryCode}&brandId=${brand.brandId}`)
                        if (data.msnList) {
                            relatedMsns = [...relatedMsns, ...data.msnList]
                        }
                    }
                }
            }
            requestPayload.relatedMsns = relatedMsns
        }

        return requestPayload;
    }

    const generateSiblingCampaigns = (requestPayload: any) => {
        let campaignsToCreate: any[] = [];

        if (requestPayload.campaignType === CAMPAIGN_TYPES.CATEGORY_BASED_CAMPAIGN) {
            if (requestPayload.categories.length === 0) {
                toast.error("Please select at least one category");
                return campaignsToCreate;
            } else {
                requestPayload.categories.forEach((category: any) => {
                    const campaignCopy = {
                        ...requestPayload,
                        categoryId: category.categoryCode,
                        categoryName: category.categoryName,
                        category: category,
                        campaignName: `${requestPayload.campaignName}_${category.categoryName}` // TODO Ask from business team 
                    };
                    campaignsToCreate.push(campaignCopy);

                    if (requestPayload.isApplicableForApp) {
                        const appCampaignCopy = {
                            ...campaignCopy,
                            platform: "APP",
                            campaignName: `${requestPayload.campaignName}_${category.categoryName}_APP`
                        };
                        campaignsToCreate.push(appCampaignCopy);
                    }
                });
            }
        }


        if (requestPayload.campaignType === CAMPAIGN_TYPES.BRAND_BASED_CAMPAIGN) {
            if (requestPayload.brands === 0) {
                toast.error("Please select at least one brand");
                return campaignsToCreate;
            }
            else {
                requestPayload.brands.forEach((brand: any) => {
                    const campaignCopy = {
                        ...requestPayload,
                        brandId: brand.id,
                        brandName: brand.name,
                        brand: brand,
                        campaignName: `${requestPayload.campaignName}_${brand.name}` // TODO Ask from business team 
                    };
                    campaignsToCreate.push(campaignCopy);

                    if (requestPayload.isApplicableForApp) {
                        const appCampaignCopy = {
                            ...campaignCopy,
                            platform: "APP",
                            campaignName: `${requestPayload.campaignName}_${brand.name}_APP`
                        };
                        campaignsToCreate.push(appCampaignCopy);
                    }
                });
            }
        }

        return campaignsToCreate;
    }

    const saveCampaignHandler = async () => {
        try {
            campaign.uniqueIdentifier = uuidv4();
            validateCampaign(campaign);

            let requestPayload = await prepareCampaignPayload(campaign);
            validateCampaignType(requestPayload);

            if (!requestPayload.videoUnit && !requestPayload.promotedBrandUnit && !requestPayload.bannerAdUnit && !requestPayload.featuredProductUnit && !requestPayload.bannerTypes) {
                throw new Error("At least one campaign type is required.");
            }

            const campaignsToCreate: any = [requestPayload]

            if (requestPayload.isApplicableForApp) {
                const appCampaignCopy = {
                    ...requestPayload,
                    platform: "APP",
                    campaignName: `${requestPayload.campaignName}_APP`
                }
                campaignsToCreate.push(appCampaignCopy);
            }
            console.log(JSON.stringify(requestPayload, null, 2))
            createCampaignsSequentially(campaignsToCreate)
        } catch (err: any) {
            console.error(err);
            toast.error(err.message);
        }
    };

    const addSectionHandler = () => {
        if (sections.length < lookups.CampaignSectionType.length) {
            let newSection = {
                "id": uuidv4(),
                "sectionType": ""
            }
            setSections([...sections, newSection])
        }
        else {
            toast.error("Please rethink about it.")
        }
        return
    }

    const removeSectionHandler = (section: any) => {
        if (section.sectionType === CAMPAIGN_SECTION_TYPES.BANNER_AD_UNIT) {
            setcampaign({
                ...campaign,
                bannerAdUnit: {
                    "banners": []
                }
            })
        }
        if (section.sectionType === CAMPAIGN_SECTION_TYPES.FEATURED_PRODUCT_UNIT) {
            setcampaign({
                ...campaign,
                featuredProductUnit: {
                    "msns": []
                }
            })
        }
        if (section.sectionType === CAMPAIGN_SECTION_TYPES.PROMOTED_BRAND_UNIT) {
            setcampaign({
                ...campaign,
                promotedBrandUnit: {
                    "banners": []
                }
            })
        }
        if (section.sectionType === CAMPAIGN_SECTION_TYPES.VIDEO_UNIT) {
            setcampaign({
                ...campaign,
                videoUnit: {
                    "banners": []
                }
            })
        }

        if (sections.length === 1) {
            toast.error("Please rethink about it.")
            return
        }
        setSections([...sections.filter((d: any) => d.id !== section.id)])
    }

    console.log("campaign", campaign)

    return (<>
        {viewBannerDimension && <ViewBannerDimensionPopup viewBannerDimension={viewBannerDimension} setViewBannerDimension={setViewBannerDimension} />}
        <div className="flex mainWrapper w-full">
            <div className="sidenav-wrapper">
                <SideNavbar />
            </div>
            <div className="right-container">
                <div className="header-container">
                    <Header />
                </div>
                <div className="body-container">
                    <CampaignHeader pageMode={pageMode} viewBannerDimension={viewBannerDimension} setViewBannerDimension={setViewBannerDimension}
                        isLoading={isLoading} saveCampaignHandler={saveCampaignHandler} />

                    {/* Common form */}
                    <CommonForm pageMode={pageMode} lookups={lookups} campaign={campaign} setcampaign={setcampaign} />

                    {/* Brand Based Campaign */}
                    {campaign.campaignType === CAMPAIGN_TYPES.BRAND_BASED_CAMPAIGN && <BrandBasedCampaign
                        pageMode={pageMode} campaign={campaign} setcampaign={setcampaign}
                        lookups={lookups} sections={sections} setSections={setSections} addSectionHandler={addSectionHandler}
                        removeSectionHandler={removeSectionHandler} />}

                    {/* Category Based Campaign */}
                    {campaign.campaignType === CAMPAIGN_TYPES.CATEGORY_BASED_CAMPAIGN && <CategoryBasedCampaign
                        pageMode={pageMode} campaign={campaign} setcampaign={setcampaign}
                        lookups={lookups} sections={sections} setSections={setSections} addSectionHandler={addSectionHandler}
                        removeSectionHandler={removeSectionHandler} />}

                    {/* Brand Category Based Campaign */}
                    {campaign.campaignType === CAMPAIGN_TYPES.BRAND_CATEGORY_BASED_CAMPAIGN && <BrandCategoryBasedCampaign
                        pageMode={pageMode} campaign={campaign} setcampaign={setcampaign}
                        lookups={lookups} sections={sections} setSections={setSections} addSectionHandler={addSectionHandler}
                        removeSectionHandler={removeSectionHandler} />}

                    {/* MSN Based Campagin */}
                    {campaign.campaignType === CAMPAIGN_TYPES.MSNS_BASED_CAMPAIGN && <MSNBasedCampaign
                        pageMode={pageMode} campaign={campaign} setcampaign={setcampaign}
                        lookups={lookups} sections={sections} setSections={setSections} addSectionHandler={addSectionHandler}
                        removeSectionHandler={removeSectionHandler} />}

                    {/* Product Based Campagin */}
                    {[CAMPAIGN_TYPES.PRODUCT_BASED_CAMPAIGN, CAMPAIGN_TYPES.PDP_SPONSORED_ADUNIT].includes(campaign.campaignType) && <ProductBasedCampaign
                        pageMode={pageMode} campaign={campaign} setcampaign={setcampaign}
                        lookups={lookups} sections={sections} setSections={setSections} addSectionHandler={addSectionHandler}
                        removeSectionHandler={removeSectionHandler} />}

                    {/* Page Based Campagin */}
                    {[CAMPAIGN_TYPES.ORDER_CONFIRMATION_PAGE_CAMPAIGN, CAMPAIGN_TYPES.HOME_PAGE_ONSCROLL_ADUNIT].includes(campaign.campaignType) && < PageBasedCampaign
                        pageMode={pageMode} campaign={campaign} setcampaign={setcampaign}
                        lookups={lookups} sections={sections} setSections={setSections} addSectionHandler={addSectionHandler}
                        removeSectionHandler={removeSectionHandler} />}
                </div>
            </div>
        </div>
    </>)
}

export default Campaign