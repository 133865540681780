import { useEffect, useState } from "react";
import { JsonToTable } from "react-json-to-table";
import { useParams } from "react-router-dom";

interface BookingDetails {
    [key: string]: any;
}

const Booking: React.FC = () => {
    const params = useParams<{ bookingId?: string }>(); // Type the route parameters
    const [bookingDetails, setBookingDetails] = useState<BookingDetails>({});

    useEffect(() => {
        if (params.bookingId) {
            const storedData = localStorage.getItem(params.bookingId);
            if (storedData) {
                try {
                    setBookingDetails(JSON.parse(storedData));
                } catch (error) {
                    console.error("Failed to parse booking details from localStorage:", error);
                }
            }
        }
    }, [params]);

    return (
        <>
            <JsonToTable json={bookingDetails} />
        </>
    );
};

export default Booking;
