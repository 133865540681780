import React from 'react';
import Section from './Section';
import toast from 'react-hot-toast';
import { CAMPAIGN_TYPES } from '../../../utils/constants';

const PageBasedCampaign = (props: any) => {
    const { campaign, setcampaign, sections, pageMode } = props

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/csv') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvData = e.target?.result as string;
                const rows = csvData.split('\n');
                const selectedColumnIndex = 0;

                const dataArray = Array.from(
                    new Set(
                        rows
                            .map((row) => row.split(',')[selectedColumnIndex]?.trim().toUpperCase())
                            .filter((d) => d?.startsWith('MSN') && d.length > 8)
                    )
                );

                if (dataArray.length === 0) {
                    toast.error("File contains an empty product list.")
                    return
                }

                setcampaign({
                    ...campaign,
                    relatedMsns: dataArray
                })
            };
            reader.readAsText(file);
        } else {
            toast.error("Please upload a valid CSV file.")
        }
    };

    const downloadCsvFile = () => {
        const csvContent = "data:text/csv;charset=utf-8," +
            ["MSNS", ...campaign.relatedMsns].join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "INPUT_MSN_LIST.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {/*SECTIONS */}
            {[CAMPAIGN_TYPES.ORDER_CONFIRMATION_PAGE_CAMPAIGN].includes(campaign.campaignType) && sections.map((d: any, index: number) =>
                <Section key={index} currentSection={d} {...props} />
            )}

            {[CAMPAIGN_TYPES.HOME_PAGE_ONSCROLL_ADUNIT].includes(campaign.campaignType) &&
                <div className="flex items-center w-full mb-7 last:mb-0 mx-5 mt-5 mb-2.5 bg-white p-5 rounded border border-[#EAEAEA]">
                    <label className="text-xs font-semibold text-[#303030] w-48">Input MSN List:</label>
                    <input type="file" className='text-xs font-semibold text-[#303030]' accept=".csv" onChange={(e) => handleFileChange(e)} />
                    {["VIEW", "EDIT"].includes(pageMode) && <button onClick={downloadCsvFile} className='text-blue-500 font-semibold text-[11px]'>Download Previous File</button>}
                </div>
            }

        </>
    )
}

export default PageBasedCampaign