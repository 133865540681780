import React, { useState } from "react"
import { AiOutlineDown } from "react-icons/ai"
import { BsBell } from "react-icons/bs"
import { RxAvatar } from "react-icons/rx"
import { useSelector } from "react-redux"
import { TbLogout } from "react-icons/tb"
import { useDispatch } from "react-redux"
import { resetAuthState } from "../../../core/actionCreators/authActionCreators"
import { resetLmsState } from "../../../core/actionCreators/lmsActionCreator"

const UserProfile = () => {
    const dispatch = useDispatch()
    let { user } = useSelector((s: any) => s.authStore);
    const [showLogoutModal, setShowLogoutModal] = useState(false)


    const logoutHandler = () => {
        localStorage.clear()
        dispatch(resetAuthState())
        dispatch(resetLmsState())
        window.location.href = "/"
    }

    return (<>
        <div className="flex header-right-fix justify-end items-center">
            <div className="header-notification mr-15">
                <BsBell className='bellicon' />
            </div>
            <div className="header-profile flex items-center">
                <p>{user.firstName + " " + user.lastName} </p>
                <AiOutlineDown size={12} className='icon-down' onClick={() => setShowLogoutModal(!showLogoutModal)} />
                <span className="avatar"><RxAvatar size={30} /></span>
                {showLogoutModal && <div className="logout">
                    <div className="name-n-email flex items-center">
                        <h2 className="firstletter flex items-center justify-center">{user?.firstName?.[0].toUpperCase()}</h2>
                        <div className="fullname">
                            <h3>{user?.firstName}</h3>
                            <p className="email">{user?.email}</p>
                        </div>
                    </div>
                    <div onClick={logoutHandler} className="logoutBtn"><TbLogout /> <h3>Logout</h3></div>
                </div>}
            </div>
        </div>
    </>)


}
export default UserProfile