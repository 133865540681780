let CMS_API_BASE_URL = process.env.REACT_APP_CMS_SERVICE;
let GATEWAY_BASE_URL = process.env.REACT_APP_GATEWAY_SERVICE;
let GATEWAY_BASE_URL_V2 = process.env.REACT_APP_GATEWAY_SERVICE_V2
// console.log(`CMS_API_BASE_URL: ${CMS_API_BASE_URL}`)
// console.log(`GATEWAY_BASE_URL: ${GATEWAY_BASE_URL}`)

export const apis = {
  LOGIN_WITH_MICROSOFT: `${CMS_API_BASE_URL}/cmsApi/v1/auth/login/microsoft`,

  GET_ARTICLE_CATEGORY_ID: `${GATEWAY_BASE_URL}/nodeApi/v1/category/getcategorybyid?catId=`,
  CREATE_ARTICLE: `${CMS_API_BASE_URL}/cmsApi/v1/article/createArticle`,
  GET_ARTICLE_LISTING: `${CMS_API_BASE_URL}/cmsApi/v1/article/getArticles?`,
  GET_ARTICLE_LISTING_BY_ID: `${CMS_API_BASE_URL}/cmsApi/v1/article/getArticle/`,
  PUBLISH_ARTICLE: `${CMS_API_BASE_URL}/cmsApi/v1/article/publishArticle?`,
  UPDATE_ARTICLE: `${CMS_API_BASE_URL}/cmsApi/v1/article/updateArticle`,
  DELETE_ARTICLE_LIST: `${CMS_API_BASE_URL}/cmsApi/v1/article/deleteArticle/`,

  GET_LAYOUT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/getLayoutById/`,
  GET_LAYOUTS: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/getLayouts?`,
  CREATE_LAYOUT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/createLayout`,
  DELETE_LAYOUT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/deleteLayoutById/`,
  GET_STATIC_FORM_FIELDS: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/getStaticFormFields`,
  CREATE_COMPONENT_V2: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/createComponentV2`,
  GET_COMPONENT_BY_ID: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/getComponentById?`,
  GET_POPULATED_LAYOUT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/getPopulatedLayout/`,
  UPDATE_COMPONENT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/updateComponent`,
  UPDATE_COMPONENT_STATUS: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/updateStatusOfComponent`,
  GET_COMPONENTS: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/getComponents?`,
  COPY_COMPONENT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/copyComponent`,
  SET_LAYOUT_JSON_BY_LAYOUT_CODE: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/getLayoutJsonByLayoutCode?`,
  DELETE_COMPONENT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/hardDeleteComponentById/`,
  GET_POPULATED_COMPONENT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/getPopulatedComponent/`,
  CREATE_ELEMENT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/createElement`,
  UPDATE_ELEMENT_STATUS: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/updateStatusofElement`,
  UPDATE_ELEMENT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/updateAttributesOfElement`,
  DELETE_ELEMENT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/hardDeleteElementById/`,

  CLEAR_CACHE: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/clearCache?`,
  CLEAR_CACHE_BY_LAYOUT: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/clearCacheByLayout?`,
  UPLOAD_IMAGE: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/uploadImage`,
  CONVERT_TO_WEBP: `${GATEWAY_BASE_URL_V2}/common/convertToWebp`,
  UPLOAD_FILE: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/uploadFile`,

  GET_CAMPAIGNS: `${CMS_API_BASE_URL}/cmsApi/v1/adsense/searchCampaign`,
  GET_LOOKUPS: `${CMS_API_BASE_URL}/cmsApi/v1/adsense/getLookups?`,
  GET_BRANDS: `${GATEWAY_BASE_URL_V2}/search/getAllBrands`,
  GET_CAMPAIGN: `${CMS_API_BASE_URL}/cmsApi/v1/adsense/getCampaignById/`,
  CREATE_CAMPAIGN: `${CMS_API_BASE_URL}/cmsApi/v1/adsense/createCampaign`,
  UPDATE_CAMPAIGN_STATUS: `${CMS_API_BASE_URL}/cmsApi/v1/adsense/updateStatusOfCampaign?`,
  GET_CATEGORIES: `${GATEWAY_BASE_URL}/nodeApi/v1/search/getAllCategoriesV2`,

  //TODO: make it configurable
  GET_SEGREGATED_CATEGORIES: `${GATEWAY_BASE_URL_V2}/search/getSegregatedCategories?`,
  GET_SEGREGATED_CATEGORIES_IN_BULK: `${GATEWAY_BASE_URL_V2}/search/getSegregatedCategoriesInBulk`,
  GET_BRANDS_BY_CATEGORYIDS: `${GATEWAY_BASE_URL_V2}/search/getBrandsByCategoryIds`,

  GET_BRANDS_BY_CATEGORY: `${GATEWAY_BASE_URL_V2}/search/getBrandsByCategoryIds`,

  FETCH_PRODUCT_BY_BRAND_CATEGORY: `${GATEWAY_BASE_URL_V2}/search/fetchProductsByCategoryAndBrand?`,

  DOWNLOAD_FILE: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/downloadFile?fileUrl=`,
  DELETE_FILE: `${CMS_API_BASE_URL}/cmsApi/v1/cms/layout/deleteFile?fileName=`,
  DELAY_COMMUNICATION_VIA_WHATSAPP: `${CMS_API_BASE_URL}/cmsApi/v1/campaign/delayCommunicationViaWhatsapp`,
  GET_REVIEWS: `${CMS_API_BASE_URL}/cmsApi/v1/reviews/getReviews?`,
  UPDATE_REVIEW: `${CMS_API_BASE_URL}/cmsApi/v1/reviews/updateReview`,
  DOWNlOAD_REVIEWS: `${CMS_API_BASE_URL}/cmsApi/v1/reviews/download/?email=`,
  UPLOAD_REVIEWS: `${CMS_API_BASE_URL}/cmsApi/v1/reviews/updateReviews`,
  REVIEW_REPLY: `${CMS_API_BASE_URL}/cmsApi/v1/reviews/reviewReply`,

  UPLOAD_PRODUCT_DESCRIPTION: `${CMS_API_BASE_URL}/cmsApi/v1/util/generateProductDescription`,
  DOWNLOAD_PRODUCT_DESCRIPTION: `${CMS_API_BASE_URL}/cmsApi/v1/util/downloadProductDescription/`,
  TRACK_PRODUCT_DESCRIPTION: `${CMS_API_BASE_URL}/cmsApi/v1/util/trackProductDescription/`,

  GET_LIST_OF_BRAND_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/getBrandBlocks?`,
  DELETE_BRAND_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/deleteBrandBlock/`,
  DELETE_BRAND_BLOCK_ELEMENT: `${CMS_API_BASE_URL}/api/cms/block/deleteBrandBlockElement/`,
  CREATE_BRAND_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/createBrandBlock`,
  GET_BRAND_BLOCK_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/getBrandBlockById/`,
  UPDATE_BRAND_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/updateBrandBlock/`,

  GET_LIST_OF_CATEGORY_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/getCategoryBlocks?`,
  CREATE_CATEGORY_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/createCategoryBlock`,
  GET_CATEGORY_BLOCK_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/getCategoryBlockById/`,
  UPDATE_CATEGORY_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/updateCategoryBlock/`,
  DELETE_CATEGORY_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/deleteCategoryBlock/`,
  DELETE_CATEGORY_BLOCK_ELEMENT: `${CMS_API_BASE_URL}/api/cms/block/deleteCategoryBlockElement/`,

  GET_STATIC_HTML_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/getHtmlBlocks?`,
  CREATE_STATIC_HTML_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/createHtmlBlock`,
  GET_STATIC_HTML_BLOCK_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/getHtmlBlockById/`,
  UPDATE_STATIC_HTML_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/updateHtmlBlock/`,
  DELETE_STATIC_HTML_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/deleteHtmlBlock/`,

  GET_LIST_OF_IMAGE_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/getImageBlocks?`,
  CREATE_IMAGE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/createImageBlock`,
  UPDATE_IMAGE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/updateImageBlock/`,
  DELETE_IMAGE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/deleteImageBlock/`,
  GET_IMAGE_BLOCK_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/getImageBlockById/`,
  DELETE_IMAGE_BLOCK_ELEMENT: `${CMS_API_BASE_URL}/api/cms/block/deleteImageBlockElement/`,

  GET_PRODUCT_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/getProductBlocks?`,
  CREATE_PRODUCT_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/createProductBlock`,
  GET_PRODUCT_BLOCK_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/getProductBlockById/`,
  UPDATE_PRODUCT_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/updateProductBlock/`,
  DELETE_PRODUCT_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/deleteProductBlock/`,
  DELETE_PRODUCT_BLOCK_ELEMENT: `${CMS_API_BASE_URL}/api/cms/block/deleteProductBlockElement/`,
  PRODUCT_BLOCK_UPLOAD_CSV_FILE: `${CMS_API_BASE_URL}/api/cms/block/productBlock/uploadCsvfile/`,

  GET_GENERAL_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/generalBlocks?`,
  CREATE_GENERAL_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/createGeneralBlock`,
  GET_GENERAL_BLOCK_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/getGeneralBlock/`,
  UPDATE_GENERAL_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/updateGeneralBlock/`,
  DELETE_GENERAL_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/deleteGeneralBlock/`,
  DELETE_GENERAL_BLOCK_ELEMENT: `${CMS_API_BASE_URL}/api/cms/block/deleteGeneralBlockElement/`,

  GET_TEMPLATE_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/templateBlocks?`,
  CREATE_TEMPLATE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/createTemplateBlock`,
  GET_TEMPLATE_BLOCK_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/getTemplateBlock/`,
  UPDATE_TEMPLATE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/updateTemplateBlock/`,
  DELETE_TEMPLATE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/deleteTemplateBlock/`,
  DELETE_TEMPLATE_BLOCK_ELEMENT: `${CMS_API_BASE_URL}/api/cms/block/deleteTemplateBlockElement/`,

  GET_BLOCK_TYPE_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/blockTypes?`,
  CREATE_BLOCK_TYPE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/createBlockType`,
  GET_BLOCK_TYPE_BLOCK_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/getBlockType/`,
  UPDATE_BLOCK_TYPE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/updateBlockType/`,
  DELETE_BLOCK_TYPE_BLOCK: `${CMS_API_BASE_URL}/api/cms/block/deleteBlockType/`,

  GET_BLOCK_LAYOUTS: `${CMS_API_BASE_URL}/api/cms/block/layouts?`,
  GET_BLOCK_LAYOUT_BY_ID: `${CMS_API_BASE_URL}/api/cms/block/layout/`,
  CREATE_BLOCK_LAYOUT: `${CMS_API_BASE_URL}/api/cms/block/createLayout`,
  UPDATE_BLOCK_LAYOUT: `${CMS_API_BASE_URL}/api/cms/block/updateLayout/`,
  GET_BLOCK_LAYOUT_TEMPLATES: `${CMS_API_BASE_URL}/api/cms/block/dropdown/getLayoutTemplates`,
  GET_BLOCK_LAYOUT_GROUP: `${CMS_API_BASE_URL}/api/cms/block/dropdown/getLayoutGroups`,
  GET_BLOCK_BLOCK_TYPES: `${CMS_API_BASE_URL}/api/cms/block/dropdown/getBlockTypes`,
  GET_BLOCK_BLOCKS: `${CMS_API_BASE_URL}/api/cms/block/dropdown/getBlocks/`,
  DELETE_BLOCK_LAYOUT_ELEMENT: `${CMS_API_BASE_URL}/api/cms/block/deleteLayoutBlockElement/`,

  BLOCK_CUSTOMER: `${GATEWAY_BASE_URL}/nodeApi/v1/address/deactivateUser`,

  GET_FBTS: `${CMS_API_BASE_URL}/cmsApi/v1/fbt/getFbts?`,
  GET_FBT_BY_ID: `${CMS_API_BASE_URL}/cmsApi/v1/fbt/getFbt/`,
  UPLOAD_FBT_FILE: `${CMS_API_BASE_URL}/cmsApi/v1/fbt/uploadFbts?`,
  DOWNLOAD_FBT_DUMP: `${CMS_API_BASE_URL}/cmsApi/v1/fbt/downloadDump?`,
  UPDATE_FBT: `${CMS_API_BASE_URL}/cmsApi/v1/fbt/updateFbt`,
  CREATE_FBT: `${CMS_API_BASE_URL}/cmsApi/v1/fbt/createFbt`,

  GET_FAQS: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/getCategorySchemaList?`,
  GET_FAQ: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/getCategorySchemaDetails?`,
  CREATE_FAQ: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/createCategorySchema`,
  DELETE_FAQ_SCHEMA: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/deleteCategorySchema`,
  DOWNLOAD_FAQ_DUMP: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/downloadAllSchema`,

  GET_ATTRIBUTES: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/getAttributesListings?`,
  GET_ATTRIBUTE_BY_ID: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/getAttributesListing/`,
  GET_ATTRIBUTE: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/getAttributesListing/`,
  CREATE_ATTRIBUTE: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/createAttributesListing`,
  UPDATE_ATTRIBUTE: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/updateAttributesListing`,
  DELETE_ATTRIBUTE_SCHEMA: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/deleteAttributesListing/`,
  DOWNLOAD_ATTRIBUTE_DUMP: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/downloadDump?`,
  BULK_UPLOAD_ATTRIBUTE: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/uploadAlps?`,
  GET_PRODUCT_COUNT: `${CMS_API_BASE_URL}/cmsApi/v1/attributeListing/getProductCount`,

  GET_SERVICEABILITY: `${CMS_API_BASE_URL}/v2/serviceability/getServiceabilityList?`,
  GET_QNA_LIST: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/getQuestionsList?`,
  ADD_UPDATE_ANSWER: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/addAnswer/`,
  PUBLISH_QUESTIONS: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/publishQuestion`,
  DISABLE_QUESTIONS: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/disableQuestions`,
  DOWNLOAD_QUESTIONS: `${CMS_API_BASE_URL}/cmsApi/v1/feedback/downloadCSV?`,
  UPLOAD_SERVICEABILITY_FILE: `${CMS_API_BASE_URL}/v2/serviceability/upload?`,

  GET_ALL_TAGS: `${CMS_API_BASE_URL}/cmsApi/v1/productTagging/getAllTags`,
  GET_TAG_DETAILS: `${CMS_API_BASE_URL}/cmsApi/v1/productTagging/getTagDetails?tagId=`,
  UPLOAD_TAG_IMAGE: `${CMS_API_BASE_URL}/cmsApi/v1/productTagging/uploadImage`,
  CREATE_PRODUCT_TAG: `${CMS_API_BASE_URL}/cmsApi/v1/productTagging/createProductTag`,
  UPDATE_PRODUCT_TAG: `${CMS_API_BASE_URL}/cmsApi/v1/productTagging/updateTagDetails`,

  GET_LIST_OF_PLA_FEEDS: `${CMS_API_BASE_URL}/cmsApi/v1/plaFeed/getPlaFeeds?`,
  CREATE_PLA_FEED: `${CMS_API_BASE_URL}/cmsApi/v1/plaFeed/createPlaFeed`,
  GET_PLA_FEED_BY_ID: `${CMS_API_BASE_URL}/cmsApi/v1/plaFeed/getPlaFeed/`,
  UPDATE_PLA_FEED: `${CMS_API_BASE_URL}/cmsApi/v1/plaFeed/updatePlaFeed/`,
  BULK_PLA_FILE: `${CMS_API_BASE_URL}/cmsApi/v1/plaFeed/uploadPlaFeeds?`,
  DOWNLOAD_PLA_DUMP: `${CMS_API_BASE_URL}/cmsApi/v1/plaFeed/downloadDump?`,

  GET_PAGEMAPPING_BY_ID: `${CMS_API_BASE_URL}/cmsApi/v1/requestMapping/getRequestMappings/`,
  GET_PAGEMAPPINGS: `${CMS_API_BASE_URL}/cmsApi/v1/requestMapping/getRequestMappings?`,
  GET_PAGEMAPPING: `${CMS_API_BASE_URL}/cmsApi/v1/requestMapping/getRequestMappings/`,
  CREATE_PAGEMAPPING: `${CMS_API_BASE_URL}/cmsApi/v1/requestMapping/createRequestMapping`,
  UPDATE_PAGEMAPPING: `${CMS_API_BASE_URL}/cmsApi/v1/requestMapping/updateRequestMapping`,
  DELETE_PAGEMAPPING_SCHEMA: `${CMS_API_BASE_URL}/cmsApi/v1/requestMapping/deleteRequestMapping`,

  GET_BOOKINGS: `${CMS_API_BASE_URL}/api/mexpress/searchBookings`,
  UPDATE_BOOKING_STATUS: `${CMS_API_BASE_URL}/api/mexpress/updateBookingStatus`,

  GET_DISTINCT_CATEGORY_CODE_BY_MSNS: `${GATEWAY_BASE_URL_V2}/search/getDistinctCategoryCodeByMsns`,

  //CMS READ
  CREATE_LANDING_PAGE: `${CMS_API_BASE_URL}/api/cmsread/landingPage/create`,
  GET_LANDING_PAGES: `${CMS_API_BASE_URL}/api/cmsread/landingPage/get?`,
  UPDATE_LANDING_PAGE: `${CMS_API_BASE_URL}/api/cmsread/landingPage/update/`,
  GET_LANDING_PAGE_BY_ID: `${CMS_API_BASE_URL}/api/cmsread/landingPage/getById/`
};
