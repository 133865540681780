import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getLandingPages } from "../../../core/services/cmsreadService";
import CommonPagination from "../CommonPagination/CommonPagination";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import DeleteBin5FillIcon from "remixicon-react/DeleteBin5FillIcon";
import Loader from "../Loader/Loader";

interface OffersMetadata {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    data: any[];
}

const LandingPageList = () => {
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const currentPage = useRef(1);
    const [offers, setOffers] = useState<OffersMetadata>({
        totalCount: 0,
        pageSize: 0,
        currentPage: 0,
        totalPage: 0,
        data: [],
    });

    function navigateToCreateLandingPageHandler() {
        navigate("/blocks/lead-gen/create")
    }

    useEffect(() => {
        fetchOffersAndPopulate()
    }, [])

    function onPageChange(_pageNumber: number) {
        currentPage.current = _pageNumber
        fetchOffersAndPopulate()
    }

    async function fetchOffersAndPopulate() {
        setLoader(true)
        try {
            const response = await getLandingPages(`page=${currentPage.current}`)
            // const response = await axios.post(apis.GET_BOOKINGS + `? page = ${ currentPage.current }`, {});
            const metadata = response?.data?.data?.metaData || {};
            const content = response?.data?.data?.content || [];
            setOffers({
                totalCount: metadata.totalElements || 0,
                pageSize: metadata.pageSize || 0,
                currentPage: metadata.currentPage || 0,
                totalPage: metadata.totalPages || 0,
                data: content
            });
        }
        catch (err) {
            console.log(err)
        }
        setLoader(false)
    }

    return (
        <>
            <div className="flex justify-between items-center pt-4 px-4">
                <p className="text-base text-[#343434]">Lead Gen LP </p>
                <button onClick={navigateToCreateLandingPageHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Create Lead Gen LP</button>
            </div>
            <div className="mt-4 campaign-list bg-white mb-[54px]">
                <table className="w-full" >
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">ID</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]"> Page Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[18%]">Brand</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[40%]">Category</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[6%]">URL</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[4%]">Update</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[4%]">Delete</th>
                        </tr>
                    </thead>

                    {loader ? <Loader /> : <tbody>
                        {offers.data.map((d) => (
                            <tr className='[&:nth-child(odd)]:bg-[#f7f7f7]'>
                                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[8%]">{d.id}</td>
                                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">{d.pageName}</td>
                                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[18%]">{d.brandName}</td>
                                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[40%]">{ }</td>
                                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[6%]">{d.url}</td>
                                <td className="px-4 py-3 text-left text-xs text-[#337ab7] w-[8%] font-semibold cursor-pointer"> <Edit2FillIcon size={14} /></td>
                                <td className="px-4 py-3 text-left text-xs text-[#337ab7] w-[8%] font-semibold cursor-pointer"> <DeleteBin5FillIcon size={14} /></td>
                            </tr>
                        ))}
                    </tbody>}

                </table>
            </div>
            {offers.data && <CommonPagination data={offers} PAGE_SIZE={15} onPageChange={onPageChange} />}
        </>
    )
}
export default LandingPageList;