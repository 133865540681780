import axios from "axios";
import { apis } from './params';

export function createLandingPage(_body: any) {
    return axios({
        method: "POST",
        url: apis.CREATE_LANDING_PAGE,
        data: _body,
    });
}

export function getLandingPages(_params: string) {
    return axios({
        method: "GET",
        url: apis.GET_LANDING_PAGES + _params,
    });
}

export function updateLandingPage(_id: string, _body: any) {
    return axios({
        method: "PUT",
        url: `${apis.UPDATE_LANDING_PAGE}${_id}`,
        data: _body,
    });
}

export function getLandingPageById(_id: string) {
    return axios({
        method: "GET",
        url: `${apis.GET_LANDING_PAGE_BY_ID}${_id}`,
    });
}
