import { useEffect, useState } from "react";
import { getBrandsService } from "../../../core/services/adsenseServices";
import { uploadImageService } from "../../../core/services/utilService";
import toast from "react-hot-toast";
import { createLandingPage, updateLandingPage } from "../../../core/services/cmsreadService";

interface Brand {
    id: string;
    name: string;
    link: string;
    brandLogo: string;
}

interface Category {
    id: string;
    name: string;
}

interface Offer {
    pageName: string;
    offerId?: string;
    brandName?: string;
    brandId: string;
    l1Category?: Category;
    l2Category?: Category;
    l3Category?: Category;
    updatedAt?: Date;
    createdAt?: Date;
    banner?: {
        pwa?: string;
        desktop?: string;
    };
    url?: string;
}


const LeadGenForm = () => {

    const [loader, setLoader] = useState<boolean>(false);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [offer, setOffer] = useState<Offer>({
        pageName: "",
        brandName: "",
        brandId: "",
        l1Category: {
            id: "",
            name: ""
        },
        l2Category: {
            id: "",
            name: ""
        },
        l3Category: {
            id: "",
            name: ""
        },
        banner: {
            pwa: "",
            desktop: ""
        },
        url: ""
    });
    const [mobileImageFile, setMobileImageFile] = useState<File | null>(null);
    const [desktopImageFile, setDesktopImageFile] = useState<File | null>(null);
    const [generatedUrl, setGeneratedUrl] = useState<string>("");

    useEffect(() => {
        getBrandsAndPopulate();
    }, []);

    // Fetch and populate brands
    async function getBrandsAndPopulate(): Promise<void> {
        setLoader(true);
        try {
            const { data } = await getBrandsService();
            if (data && data.brands) {
                setBrands(data.brands);
            }
        } catch (err) {
            console.error("Error fetching brands:", err);
        } finally {
            setLoader(false);
        }
    }

    // Utility API to upload file to S3
    async function uploadFile(file: File): Promise<string> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("bucketName", "common");
        const { data } = await uploadImageService(formData);
        return data.data.s3ImageUrl;
    }

    // Handle input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setOffer((prev) => ({ ...prev, [name]: value }));
    };

    // Handle file changes
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, type: "mobile" | "desktop") => {
        const file = e.target.files?.[0] || null;
        if (type === "mobile") {
            setMobileImageFile(file);
        } else {
            setDesktopImageFile(file);
        }
    };

    const saveOffer = async () => {
        try {
            setLoader(true);

            // Upload files and get URLs
            const mobileImageUrl = mobileImageFile ? await uploadFile(mobileImageFile) : "";
            const desktopImageUrl = desktopImageFile ? await uploadFile(desktopImageFile) : "";

            // Validate image URLs (optional)
            if (!mobileImageUrl && !desktopImageUrl) {
                throw new Error("Both mobile and desktop image URLs are missing.");
            }

            // Construct offer DTO
            const offerData = {
                ...offer,
                banner: {
                    pwa: mobileImageUrl,
                    desktop: desktopImageUrl,
                },
            };

            offerData.brandName = brands.find((b) => b.id === offerData.brandId)?.name || "";
            let url = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? 'https://www.moglix.com/' : 'https://qam.moglilabs.com/'
            offerData.url = url;

            //Create landing page
            const { data } = await createLandingPage(offerData)

            let cookedUrl = url + `bulk-enquiry/${offerData.brandName}/${data.id}/?brandId=${offerData.brandId}`
            offerData.url = cookedUrl

            //Update url
            await updateLandingPage(data.id, offerData);

            setGeneratedUrl(cookedUrl)
        } catch (err) {
            console.error("Error saving offer:", err);
            alert("Failed to save the offer. Please try again.");
        } finally {
            setLoader(false);
        }
    };

    function copyToClipboard() {
        navigator.clipboard.writeText(generatedUrl);
        toast.success("Copied successfully!")
    }

    return (
        <div className="mx-5 mt-5 mb-2.5">
            <div className="bg-white p-5 rounded border border-[#EAEAEA]">
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Page Name :</p>
                    <div className="w-2/5">
                        <input
                            type="text"
                            name="pageName"
                            placeholder="Enter Page Name"
                            className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-1.5 text-[#303030]"
                            value={offer.pageName}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Brand Name* :</p>
                    <div className="w-2/5">
                        <select
                            name="brandId"
                            className="w-full rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            value={offer.brandId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            {brands.map((b) => (
                                <option key={b.id} value={b.id}>
                                    {b.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Mobile Image :</p>
                    <div className="w-2/5">
                        <input
                            accept=".jpg,.png,.jpeg"
                            type="file"
                            className="w-full rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            onChange={(e) => handleFileChange(e, "mobile")}
                        />
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Desktop Image :</p>
                    <div className="w-2/5">
                        <input
                            accept=".jpg,.png,.jpeg"
                            type="file"
                            className="w-full rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            onChange={(e) => handleFileChange(e, "desktop")}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <button
                    type="button"
                    className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded"
                    onClick={saveOffer}
                    disabled={loader}
                >
                    {loader ? "Saving..." : "Save Page"}
                </button>
            </div>
            {generatedUrl && (
                <div className="bg-white p-5 rounded border border-[#EAEAEA] mt-5">
                    <p className="text-base font-bold mb-5 text-[#303030]">Generated Url</p>
                    <div className="flex items-center mb-[30px]">
                        <input
                            type="url"
                            className="w-1/2 rounded-sm border border-gray-300 text-xs px-2.5 py-1.5 text-[#303030]"
                            value={generatedUrl}
                            readOnly
                        />
                        <button
                            type="button"
                            className="h-[30px] ml-5 px-4 text-xs text-white border font-medium border-[#3B86FF] bg-[#3B86FF] rounded"
                            onClick={copyToClipboard}
                        >
                            Copy
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default LeadGenForm;