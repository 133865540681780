import BlocksSubHeader from "../../../components/Blocks/BlocksSubHeader"
import Header from "../../../components/Header/Header"
import LandingPageList from "../../../components/LeadGenBlock/LandingPageList"
import SideNavbar from "../../../components/Navbar/SideNavbar"

const LandingPages = () => {
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <BlocksSubHeader />
                        <LandingPageList />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingPages