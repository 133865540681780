import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select, { OnChangeValue } from 'react-select';
import MultiCategorySelection from './MultiCategorySelection';
import MultiBrandSelection from './MultiBrandSelection';
import { CAMPAIGN_TYPES } from '../../../utils/constants';

interface BannerOption {
    value: string;
    label: string;
}

const ProductBasedCampaign = (props: any) => {
    const { campaign, setcampaign, pageMode } = props

    const [bannerOptions, setBannerOptions] = useState([
        { value: 'PDP_NATIVE_POSITION_1', label: 'PDP_NATIVE_POSITION_1' },
        { value: 'PDP_NATIVE_POSITION_2', label: 'PDP_NATIVE_POSITION_2' },
    ])

    const [selectedBannerTypes, setSelectedBannerTypes] = useState<BannerOption[]>([]);
    const [inputType, setInputType] = useState(campaign.inputType || "MSN_LIST")

    useEffect(() => {
        setcampaign({ ...campaign, inputType: inputType })
        if (campaign.bannerTypes?.length) {
            const tempBannerTypes = campaign.bannerTypes.map((bannerType: any) => ({
                value: bannerType,
                label: bannerType
            }));
            setSelectedBannerTypes(tempBannerTypes);
        }
    }, []);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fileContext: string) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/csv') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const csvData = e.target?.result as string;
                const rows = csvData.split('\n');
                const selectedColumnIndex = 0;

                const dataArray = Array.from(
                    new Set(
                        rows
                            .map((row) => row.split(',')[selectedColumnIndex]?.trim().toUpperCase())
                            .filter((d) => d?.startsWith('MSN') && d.length > 8)
                    )
                );

                if (dataArray.length === 0) {
                    toast.error("File contains an empty product list.")
                    return
                }

                if (fileContext === "CAMPAIGN_MSN_LIST") {
                    setcampaign({
                        ...campaign,
                        msns: dataArray
                    })
                }
                if (fileContext === "INPUT_MSN_LIST") {
                    setcampaign({
                        ...campaign,
                        relatedMsns: dataArray
                    })
                }
            };
            reader.readAsText(file);
        } else {
            toast.error("Please upload a valid CSV file.")
        }
    };

    const handleBannerTypeChange = (selectedOptions: OnChangeValue<BannerOption, true>) => {
        setSelectedBannerTypes([...selectedOptions as BannerOption[]]);
        setcampaign({ ...campaign, bannerTypes: selectedOptions.map((d) => d.value) })
    }

    const handleInputChange = (val: string) => {
        setInputType(val)
        setcampaign({ ...campaign, inputType: val })
    }


    const downloadCsvFile = (filename: string, data: any, header: string) => {
        // Create CSV content with specified header
        const csvContent = "data:text/csv;charset=utf-8," +
            [header, ...data].join("\n");

        // Create a downloadable link and trigger download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    const downloadPrevFile = (context: string) => {
        if (context === "CAMPAIGN_MSN_LIST") {
            const msns = campaign.msns;
            downloadCsvFile("CAMPAIGN_MSN_LIST.csv", msns, "MSNS");
        } else if (context === "INPUT_MSN_LIST") {
            const msns = campaign.relatedMsns;
            downloadCsvFile("INPUT_MSN_LIST.csv", msns, "MSNS");
        }
    };

    return (
        <div className="mx-5 mt-5 mb-2.5 bg-white p-5 rounded border border-[#EAEAEA]">
            {[CAMPAIGN_TYPES.PRODUCT_BASED_CAMPAIGN].includes(campaign.campaignType) && <div className="flex items-center w-full mb-7 last:mb-0">
                <label className="text-xs font-semibold text-[#303030] w-48">Select Input Type:</label>
                <div className='text-xs font-semibold text-[#303030]'>
                    <label className='mr-10'>
                        <input
                            type="radio"
                            name="inputType"
                            value="MSN_LIST"
                            checked={inputType === 'MSN_LIST'}
                            onChange={(e) => handleInputChange(e.target.value)}
                            className='mr-3'
                        />
                        MSN List
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="inputType"
                            value="CATEGORY_BRAND"
                            checked={inputType === 'CATEGORY_BRAND'}
                            onChange={(e) => handleInputChange(e.target.value)}
                            className='mr-3'
                        />
                        Category/Brand
                    </label>
                </div>
            </div>}

            {inputType === "MSN_LIST" && <div>

                <div className="flex items-center w-full mb-7 last:mb-0">
                    <label className="text-xs font-semibold text-[#303030] w-48">Campaign MSN List:</label>
                    <input type="file" className='text-xs font-semibold text-[#303030]' accept=".csv" onChange={(e) => handleFileChange(e, "CAMPAIGN_MSN_LIST")} />
                    {["VIEW", "EDIT"].includes(pageMode) && <button onClick={() => downloadPrevFile("CAMPAIGN_MSN_LIST")} className='text-blue-500 font-semibold text-[11px]'>Download Previous File</button>}
                </div>


                <div className="flex items-center w-full mb-7 last:mb-0">
                    <label className="text-xs font-semibold text-[#303030] w-48">Input MSN List:</label>
                    <input type="file" className='text-xs font-semibold text-[#303030]' accept=".csv" onChange={(e) => handleFileChange(e, "INPUT_MSN_LIST")} />
                    {["VIEW", "EDIT"].includes(pageMode) && <button onClick={() => downloadPrevFile("INPUT_MSN_LIST")} className='text-blue-500 font-semibold text-[11px]'>Download Previous File</button>}
                </div>

                {[CAMPAIGN_TYPES.PRODUCT_BASED_CAMPAIGN].includes(campaign.campaignType) && <div className="flex items-center w-full mb-7 last:mb-0">
                    <label className="text-xs font-semibold text-[#303030] w-48">Select Banner Type:</label>
                    <Select
                        options={bannerOptions}
                        isMulti
                        value={selectedBannerTypes}
                        onChange={handleBannerTypeChange}
                        placeholder="Select banner types"
                        className="w-2/5 text-xs text-[#303030]"
                    />
                </div>}
            </div>}

            {
                inputType === "CATEGORY_BRAND" &&
                <>

                    <div className="flex items-center w-full mb-7 last:mb-0">
                        <label className="text-xs font-semibold text-[#303030] w-48">Campaign MSN List:</label>
                        <input type="file" className='text-xs font-semibold text-[#303030]' accept=".csv" onChange={(e) => handleFileChange(e, "CAMPAIGN_MSN_LIST")} />
                        {["VIEW", "EDIT"].includes(pageMode) && <button onClick={() => downloadPrevFile("CAMPAIGN_MSN_LIST")} className='text-blue-500 font-semibold text-[11px]'>Download Previous File</button>}
                    </div>

                    <MultiCategorySelection {...props} />
                    <MultiBrandSelection  {...props} />

                    {[CAMPAIGN_TYPES.PRODUCT_BASED_CAMPAIGN].includes(campaign.campaignType) && <div className="flex items-center w-full mb-7 last:mb-0">
                        <label className="text-xs font-semibold text-[#303030] w-48">Select Banner Type:</label>
                        <Select
                            options={bannerOptions}
                            isMulti
                            value={selectedBannerTypes}
                            onChange={handleBannerTypeChange}
                            placeholder="Select banner types"
                            className="w-2/5 text-xs text-[#303030]"
                        />
                    </div>}
                </>
            }
        </div>
    );
};

export default ProductBasedCampaign;
